<template>
	<div>
		<div class="vx-row">
			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-1/5 mb-base">
				<vx-card slot="no-body">
					<div class="p-6 flex justify-between flex-row-reverse items-center">
						<span class="p-4 inline-flex rounded-full feather-icon select-none relative text-primary" style="background: rgba(var(--vs-primary),0.1);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-monitor"><rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect><line x1="8" y1="21" x2="16" y2="21"></line><line x1="12" y1="17" x2="12" y2="21"></line></svg></span>
						<div>
							<h2 class="mb-1 font-bold">{{ watchlistStats['totalDomains'] | formatNumber }}</h2>
							<span>Total Domains</span>
						</div>
					</div>
				</vx-card>
			</div>
			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-1/5 mb-base">
				<vx-card slot="no-body">
					<div class="p-6 flex justify-between flex-row-reverse items-center">
						<span class="p-4 inline-flex rounded-full feather-icon select-none relative text-warning" style="background: rgba(var(--vs-warning),0.1);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pause-circle"><circle cx="12" cy="12" r="10"></circle><line x1="10" y1="15" x2="10" y2="9"></line><line x1="14" y1="15" x2="14" y2="9"></line></svg></span>
						<div>
							<h2 class="mb-1 font-bold">{{ watchlistStats['totalServerHold'] | formatNumber }}</h2>
							<span>Total SH</span>
						</div>
					</div>
				</vx-card>
			</div>
			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-1/5 mb-base">
				<vx-card slot="no-body">
					<div class="p-6 flex justify-between flex-row-reverse items-center">
						<span class="p-4 inline-flex rounded-full feather-icon select-none relative text-success" style="background: rgba(var(--vs-success),0.1);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-thumbs-up"><path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path></svg></span>
						<div>
							<h2 class="mb-1 font-bold">{{ watchlistStats['totalActive'] | formatNumber }}</h2>
							<span>Total Active</span>
						</div>
					</div>
				</vx-card>
			</div>
			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-1/5 mb-base">
				<vx-card slot="no-body">
					<div class="p-6 flex justify-between flex-row-reverse items-center">
						<span class="p-4 inline-flex rounded-full feather-icon select-none relative text-dark" style="background: rgba(var(--vs-dark),0.1);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12" y2="17"></line></svg></span>
						<div>
							<h2 class="mb-1 font-bold">{{ watchlistStats['totalAbuse'] | formatNumber }}</h2>
							<span>Total Abuse</span>
						</div>
					</div>
				</vx-card>
			</div>
			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-1/5 mb-base">
				<vx-card slot="no-body">
					<div class="p-6 flex justify-between flex-row-reverse items-center">
						<span class="p-4 inline-flex rounded-full feather-icon select-none relative text-danger" style="background: rgba(var(--vs-danger),0.1);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info "><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="8"></line></svg></span>
						<div>
							<h2 class="mb-1 font-bold">{{ watchlistStats['abuseRate'] | formatNumber }}%</h2>
							<span>Abuse Rate</span>
						</div>
					</div>
				</vx-card>
			</div>
		</div>

		<!-- Domain List -->
		<vx-card title="Domain List">
			<div slot="no-body" class="p-6 pt-0">
				<ag-grid-vue
					:gridOptions="gridOptions"
					class="ag-theme-material w-100 mb-4 ag-grid-table"
					:columnDefs="columnDefs"
					:defaultColDef="defaultColDef"
					:rowData="tableData"
					rowSelection="multiple"
					:enableRangeSelection="true"
					:suppressCopyRowsToClipboard="true"
					colResizeDefault="shift"
					:suppressDragLeaveHidesColumns="true"
					:animateRows="false"
					:floatingFilter="true"
					:pagination="true"
					:paginationPageSize="paginationPageSize"
					:suppressPaginationPanel="true"
					:suppressRowClickSelection="true"
					:rowClassRules="rowClassRules"
					:statusBar="statusBar"
					:sideBar="sideBar"
					:rowHeight="38"
					:getContextMenuItems="getContextMenuItems"
					@row-selected="onRowSelected"
					@column-resized="onColumnResized">
				</ag-grid-vue>
				<div class="vx-row">
					<div v-if="user_role == 'admin'" class="vx-col w-full md:w-1/2 flex justify-center md:justify-start">
						<template v-if="selectedDomains.length">
							<vs-button class="md:block" @click="takedownMultiple()">Takedown</vs-button>
							<vs-button class="md:block ml-4" @click="unsuspendMultiple()">Unsuspend</vs-button>
						</template>
					</div>
					<div class="vx-col w-full" :class="{ 'md:w-1/2': user_role == 'admin' }">
						<vs-pagination
						:total="totalPages"
						:max="maxPageNumbers"
						v-model="currentPage" />
					</div>
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
		},
		props: {
			paramId: {
				type: String | Number,
				default: null
			},
		},
		data() {
			return {
				watchlistStats: {
					totalDomains: 0,
					totalServerHold: 0,
					totalActive: 0,
					totalAbuse: 0,
					abuseRate: 0,
				},
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filterParams: {
						suppressAndOrCondition: true
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				columnDefs: [
				{
					headerName: 'Domain',
					field: 'domain',
					minWidth: 275,
					width: 275,
					filter: "agTextColumnFilter",
					pinned: 'left',
					cellRenderer: (data) => {
						if(data.value) {
							return `<a href="/search?domain_name=${data.value}" target="_blank">${data.value}</a>`
						}
						else {
							return '';
						}
					},
					checkboxSelection: JSON.parse(localStorage.getItem('userDetails')).role == 'admin',
				},
				{
					headerName: 'Domain ID',
					field: 'domain_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'TLD',
					field: 'tld',
					filter: "agTextColumnFilter",
					minWidth: 100,
					width: 100,
				},
				{
					headerName: 'Source ID',
					field: 'source_id',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Source',
					field: 'source',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Category ID',
					field: 'category_id',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Category',
					field: 'category',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Transaction Date',
					field: 'transaction_date',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
					sort: 'desc',
				},
				{
					headerName: 'Detection Date',
					field: 'detection_date',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
				},
				{
					headerName: 'Action Date',
					field: 'action_date',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
				},
				{
					headerName: 'Registrar ID',
					field: 'registrar_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Registrar',
					field: 'registrar_organization',
					filter: "agTextColumnFilter",
					minWidth: 275,
					hide: true,
				},
				{
					headerName: 'Status',
					field: 'status',
					filter: "agTextColumnFilter",
					cellRenderer: (data) => {
						if(data.value) {
							switch (data.value) {
								case 'A':
									return `<div class="badge badge--success inline">${data.value}</div>`
								case 'SH':
									return `<div class="badge badge--danger inline">${data.value}</div>`
								case 'SHP':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'SHF':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'PD':
									return `<div class="badge badge--info inline">${data.value}</div>`
								case 'D':
									return `<div class="badge badge--dark inline">${data.value}</div>`
								default:
									return `<div>-</div>`
							}
						}
						else {
							return ''
						}
					},
					minWidth: 100,
				},
				{
					headerName: 'Talos',
					field: 'suspicion_level',
					filter: "agTextColumnFilter",
					cellRenderer: (data) => {
						if(data.value) {
							switch (data.value) {
								case 'high':
									return `<div class="badge badge--danger inline">${data.value}</div>`
								case 'medium':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'low':
									return `<div class="badge badge--primary inline">${data.value}</div>`
								default:
									return `<div>${data.value}</div>`
							}
						}
						else {
							return ''
						}
					},
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Whitelisted',
					field: 'whitelisted',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Locked',
					field: 'locked',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Reserved',
					field: 'reserved',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Reviewed',
					field: 'reviewed',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Manual',
					field: 'manual',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Nameservers',
					field: 'nameservers',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Registrant ID',
					field: 'registrant_id',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Registrant Email',
					field: 'registrant_email',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Registrant Phone',
					field: 'registrant_phone_voice',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Registrant State',
					field: 'registrant_state',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Registrant Country',
					field: 'registrant_country',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Source All ID',
					field: 'source_all_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Row ID',
					field: 'id',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Created At',
					field: 'created_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				{
					headerName: 'Updated At',
					field: 'updated_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					hide: true,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				],
				selectedDomains: [],
				tableData: [],
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
			paramId(val) {
				// Table Data AXIOS Call
				this.populateTableData(val);
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			updateSearchQuery(val) {
				this.gridApi.setQuickFilter(val);
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			onRowSelected(event) {
				if(event.node.selected) {
					// Push to selectedDomains
					this.pushToArray(this.selectedDomains, event.node.data, 'domain_id');
				}
				else {
					// Remove from selectedDomains
					this.removeFromArray(this.selectedDomains, event.node.data, 'domain_id');
				}
			},
			takedownMultiple() {
				// Save takedown domains
				this.$store.dispatch("setTakedownDomains", this.selectedDomains.map(x => {
					return {
						domain: x['domain'],
						domain_id: x['domain_id'],
						tld: x['tld']
					};
				}));
				// Redirect to listing
				this.$router.push({ path: '/suspension' })
			},
			unsuspendMultiple() {
				// Save takedown domains
				this.$store.dispatch("setUnsuspensionDomains", this.selectedDomains.map(x => {
					return {
						domain: x['domain'],
						domain_id: x['domain_id'],
						tld: x['tld']
					};
				}));
				// Redirect to listing
				this.$router.push({ path: '/unsuspension' })
			},
			populateTableData(id) {
				let self = this;

				this.$axiosSecure.get('/get-watch-list-domains-json', {
					params: {
						id: id
					}
				})
				.then((response) => {
					// Assign Data
					this.tableData = response.data.domainList;
					this.watchlistStats = response.data.stats;
					// Refresh Watchlist
					this.$emit('refreshWatchlist');
				})
				.catch((error) => {
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			getContextMenuItems(params) {
				let result,
					self = this;

				if(params['column'] && params['column']['colId'] && params['column']['colId'] == 'domain') {
					result = [
						{
							name: "View Domain Details",
							action: function() {
								let routeData = self.$router.resolve({name: 'search', query: {domain_name: params.value}});
								window.open(routeData.href, '_blank');
							},
						},
						'separator',
						{
							name: "Resolve Domain(s)",
							action: function() {
								let rangeSelection = self.gridApi.getCellRanges(),
									value = params['value'];

								if(rangeSelection) {
									// Get selected cells & iterate through them to find domains
									rangeSelection.forEach(item => {
										let start = Math.min(item.startRow.rowIndex, item.endRow.rowIndex),
											end = Math.max(item.startRow.rowIndex, item.endRow.rowIndex),
											isRightClicked = false;
										
										for (let i = start; i <= end; i++) {
											if(self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'] == value) {
												isRightClicked = true
											}
											window.open('https://anonymto.com/?http://' + self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'], '_blank');
										}
										
										if(!isRightClicked) {
											window.open('https://anonymto.com/?http://' + value);
										}
									});
								}
								else {
									window.open('https://anonymto.com/?http://' + value);
								}
							},
						},
						'separator',
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}
				else {
					result = [
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}

				return result;
			}
		},
		beforeMount() {
			// Table Data AXIOS Call
			this.populateTableData(this.paramId);

			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid statusbar
			this.statusBar = {
				statusPanels: [
				{
					statusPanel: "agTotalRowCountComponent",
					align: "left"
				},
				{
					statusPanel: "agFilteredRowCountComponent",
					align: "right"
				}
				]
			};

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Resize to fit columns
			this.sizeColumnsToFit();
		}
	}
</script>
